<template>
  <div class="h-full w-full flex justify-center items-start">
    <Wrapper class="max-w-lg">
      <form v-if="!emailSent && !recovery" @submit.prevent="sendEmail" class="w-full">
        <h2 class="mb-5 text-2xl text-blue-dark"><b>¿Olvidó su contraseña?</b></h2>
        <p class="mb-8 text-sm">Ingrese el correo electrónico asociado a su cuenta. Recibirá un correo con las instrucciones para restablecer su contraseña.</p>
        <BaseInput type="text" label="Ingrese su correo electrónico" class="mb-5" placeholder="Ej: pepe@perez.com" v-model="email" autocomplete="off" />
        <p v-if="feedback" class="mb-4 text-xs text-red-500">{{feedback}}</p>
        <a-button type="primary" html-type="submit" shape="round" :block="true" :loading="loading">
          ENVIAR
        </a-button>
      </form>
      <div v-if="emailSent" class="w-full check-send-mail" >
        <h2 class="text-2xl text-blue-dark"><b>¡Correo electrónico enviado!</b></h2>
        <a-icon type="check-circle" theme="twoTone"  two-tone-color="#52c41a"/>
        <p class="mb-6 text-sm font-bold">Hemos enviado a su correo electrónico las instrucciones para restablecer de su contraseña.</p>
         
        <span class="block h-px w-full bg-gray-400"></span>
        <p class="mt-5 text-xs text-gray-600">¿No recibió el correo? Recuerde revisar su carpeta de correo no deseado.</p>
       
        <b class="text-xs cursor-pointer" :class="{'text-blue':!disableResend, 'text-gray-500 pointer-events-none': disableResend }" @click="resendEmail">Realizar nuevamente la solicitud <span class="text-gray-500">{{counter}}</span></b>
        
        <p class="mt-2 text-xs text-green-600">{{ feedbackMessage }}</p>
        
      </div>
      <form v-if="recovery" @submit.prevent="sendRecovery" >
        <h2 class="mb-3 text-2xl text-left text-blue-dark"><b>Restablecer contraseña</b></h2>
        <p class="mb-5 text-left text-sm">Ingrese el código de verificación y su nueva contraseña.</p>
       <RecoveryCodeInput class="mt-6" @input="code=$event" :value="code" ref="code"/>
        <BasePassword label="Nueva contraseña" placeholder="*********" v-model="password" autocomplete="new-password" />
        <BasePassword label="Verficar nueva contraseña" placeholder="*********" v-model="verifyPassword" autocomplete="new-password" />
        <p v-if="feedback" class="mt-4 text-xs text-red-500">{{feedback}}</p>
        <a-button type="primary" html-type="submit" shape="round" class="mt-5" :block="true" :loading="loading">
          RESTABLECER CONTRASEÑA
        </a-button>
      </form>
    </Wrapper>
  </div>
</template>

<script>
import RecoveryCodeInput from '../shared/RecoveryCodeInput.vue';
export default {
  components:{RecoveryCodeInput},
  data(){
    return {
      emailSent: false,
      email: '',
      code: '',
      password: '',
      verifyPassword: '',
      loading: false,
      feedback: '',
      feedbackMessage: '',
      counter: 0,
      disableResend: false
    }
  },
  beforeDestroy(){
    this.emailSent = false;
  },
  methods: {
    reset(){
      this.emailSent = false;
    },
    async sendEmail(){
      if(this.$validator.isEmpty(this.email)){
        this.feedback = 'Ingrese un correo electrónico'
        return;
      }
      else if (this.email && !this.$validator.isEmail(this.email)) {
        this.feedback = "Ingrese un correo válido";
        return ;
      }
      this.feedback = ""
      this.loading = true;
      const {data, error} = await this.$api.sendPasswordCode(this.email);
      if(error){
        this.feedback = error ? error?.error : "El correo ingresado no existe";
        this.loading = false;
      }
      if(data){
        this.feedbackMessage = data.message;
        this.emailSent = true;
      }
    },
    async resendEmail(){
      this.feedbackMessage = '';

      const {data, error} = await this.$api.resendPasswordCode(this.email);
      if(error){
        this.$notification.error({
              message: 'Notificación',
              description: error ? error?.error :'Hubo un error al enviar el correo',
          });
      }
      if(data){
        this.feedbackMessage = 'Correo enviado exitosamente';
        this.timer();
      }
    },
    async sendRecovery(){
      this.feedback = '';
      this.loading = true;
      if(!this.$refs.code.checkInput()) {
        this.loading=false
        return
      }
      else if(this.$validator.isEmpty(this.code) || this.$validator.isEmpty(this.password)){
        this.feedback = "Verifique la informacion";
        this.loading = false;
        return;
      } else if(this.password != this.verifyPassword){
        this.feedback = "Las contraseñas no coinciden";
        this.loading = false;
        return;
      } else if(!this.$validator.isStrongPassword(this.password)){
        this.feedback = 'La contraseña no cumple los requerimientos';
        this.loading = false;
        return;
      }else {
        this.feedback = "";
      }
      let form = {
        email: this.email?.toLowerCase(),
        code: this.code,
        password: this.password
      }
      const {data, error} = await this.$api.changePassword(form);
      if(error){
        this.feedback = error ? error?.error :'El código es incorrecto';
        this.loading = false;
        return;
      }
      if(data){
        this.$notification.success({
            message: 'Notificación',
            description: 'Se actualizó correctamente la contraseña',
        });
        this.$router.push('/login');
        this.email = '';
        this.code = '';
        this.password = '';
        this.loading = false;
      }

      return;
    },
    timer(){
      this.disableResend = true;
      this.counter = 60;
      let interval = setInterval(()=>{
        if(this.counter < 1){
          clearInterval(interval)
          this.counter = 0;
          this.disableResend = false;
        } else {
          this.counter = this.counter - 1;  
        }  
      },1000)
    }
  },
  computed: {
    recovery: function(){
      let path = this.$route.path;
      if(path == '/recovery/restore'){
        this.email = this.$route.query.email;
        if(this.email == ''){
          this.$router.push('/recovery')
        }
        return true;
      } else { 
        return false 
      }
    }
  }
}
</script>

<style >
.check-send-mail >.anticon {
  margin: .5em;
  font-size: 48px;
}

</style>